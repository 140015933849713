<template>
  <page-main title back>
    <div class="tag-box">
      <div>产品名称：</div>
      <div>
        <el-input
          v-if="isShowEdit"
          v-model="createTagNameText"
          v-clear-emoij
          maxlength="8"
          class="none-input"
          show-word-limit
          placeholder="请输入标签名称"
        />
        <span v-else class="none-input">{{ createTagNameText }}</span>
        <el-button
          v-show="isShowEdit"
          v-debounce="[onConfirm, 'click']"
          type="primary"
          class="w100"
        >
          {{ isCreate ? '添加标签' : '保存标签' }}
        </el-button>
        <el-button
          v-if="!isCreate"
          type="text"
          style="margin-left: 20px;"
          :icon="!clickEditor?'el-icon-edit':''"
          @click="onCancelEditTitle"
        >
          {{ clickEditor ? "取消" : "编辑" }}
        </el-button>
      </div>
    </div>
    <div class="table-box">
      <span>关键信息：</span>
      <el-button
        v-if="!isCreate "
        type="primary"
        icon="el-icon-plus"
        @click="onAddKnowledge"
      >
        添加关键信息
      </el-button>
    </div>
    <el-table
      :data="dataList"
      style="width: 100%;"
    >
      <el-table-column
        prop=""
        label="关键信息"
        fixed
      >
        <template slot-scope="scope">
          <div v-if="onGetRowStatus(scope.row)">
            <el-input
              v-model="currentObj.knowledgePointName"
              maxlength="40"
              show-word-limit
              placeholder="请输入关键信息"
            />
          </div>
          <span v-else>{{ scope.row.knowledgePointName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="分类"
        width="240px"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="onGetRowStatus(scope.row)">
            <el-select v-model="currentObj.pointType" placeholder="请选择类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <span v-else>{{ oGetTypeVal(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="操作"
        width="120px"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="onGetRowStatus(scope.row)">
            <el-button
              type="text"
              @click="onEditKnowledge(scope.row, true)"
            >
              保存
            </el-button>
            <el-button type="text" @click="onCancelEdit">取消</el-button>
          </div>
          <div v-else>
            <el-button type="text" style="margin-right: 10px;" @click="onEditKnowledge(scope.row)">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="onDelete(scope.row)">
              <el-button
                slot="reference"
                style="color: #e8312f;"
                type="text"
              >
                删除
              </el-button>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </page-main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProductLabelDetail',
  data() {
    return {
      clickEditor: false,
      cacheText: '',
      createTagNameText: '',
      tagId: '',
      dataList: [],
      currentObj: {},
      options: [
        {
          label: '诊断',
          value: 1
        },
        {
          label: '品牌',
          value: 2
        },
        {
          label: '使用',
          value: 3
        },
        {
          label: '类别',
          value: 4
        },
        {
          label: '非诊断',
          value: 5
        }
      ],
      isEditing: false
    }
  },
  computed: {
    dataType() {
      return this.$route.query.dataType || '1'
    },
    currentTagId: {
      get() {
        return this.tagId || this.$route.params.id
      },
      set(val) {
        this.tagId = val
      }
    },
    isCreate() {
      return this.currentTagId === 'create'
    },
    isShowEdit() {
      return this.isCreate || this.clickEditor
    },
    isTopOrg() {
      return this.$store.state.user.userInfo.isTopOrg
    },
    currentGroupId() {
      return this.$route.query.groupId || ''
    },
    currentTagName() {
      return this.createTagNameText.replace(/^\s*|\s*$/g, '')
    }

  },
  mounted() {
    this.onGetDetail()
  },
  methods: {
    ...mapActions(['onCreateTag', 'onEditTag']),
    oGetTypeVal(row) {
      const obj = {
        1: '诊断',
        2: '品牌',
        3: '使用',
        4: '类别',
        5: '非诊疗'
      }
      return obj[String(row.pointType)] || '/'
    },
    onGetRowStatus(row) {
      return this.isEditing && row.knowledgePointId === this.currentObj.knowledgePointId
    },
    onAddKnowledge() {
      if (this.isEditing) return
      this.isEditing = true
      this.currentObj = {
        id: '',
        knowledgePointId: '',
        tagId: this.currentTagId,
        knowledgePointName: '',
        pointType: ''
      }
      this.dataList.push(this.currentObj)
    },
    onConfirm() {
      if (this.isCreate) {
        this.onCreate()
      } else {
        this.onEdit()
      }
    },

    onCreate() {
      if (!this.$auth('createTag') && !this.isTopOrg) return this.$message.error('暂无权限')
      const text = this.currentTagName
      if (!text) return this.$message.error('请输入标签名称')
      const req = {
        groupId: this.currentGroupId,
        tagName: text,
        tagType: 1
      }
      this.onCreateTag(req).then(
        res => {
          this.currentTagId = res.data
          this.cacheText = this.createTagNameText
          this.$message.success('标签创建成功')
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    onEdit() {
      this.onEditTag({ tagId: this.currentTagId, tagName: this.currentTagName }).then(() => {
        this.cacheText = this.createTagNameText
        this.$message.success('保存成功')
        this.clickEditor = false
      }, rea => {
        this.$message.error(rea)
      })
    },
    onEditKnowledge(row, isSave = false) {
      if (isSave) {
        if (!this.currentObj.knowledgePointName) return this.$message.error('请输入关键信息')
        if (!this.currentObj.pointType) return this.$message.error('请输入选择类型')
        this.onSaveKnowledge()
      } else {
        if (this.isEditing) return
        this.isEditing = true
        this.currentObj = {...row}
      }
    },
    onCancelEdit() {
      if (!this.currentObj.knowledgePointId) {
        this.dataList.pop()
      }
      this.isEditing = false
    },
    onCancelEditTitle() {
      this.clickEditor = !this.clickEditor
      this.createTagNameText = this.cacheText
    },
    onSaveKnowledge() {
      const data = {
        knowledgePointId: this.currentObj.knowledgePointId,
        knowledgePointName: this.currentObj.knowledgePointName,
        pointType: this.currentObj.pointType,
        productTagId: this.currentTagId
      }
      this.$axios.post(this.$API.saveProductKnowledgePointList, data).then(() => {
        this.$message.success('保存成功')
        this.isEditing = false
        this.onGetDetail()
      }, err => {
        this.$message.error(err.message)
      })

    },
    onDelete(row) {
      this.$axios.get(this.$API.delProductKnowledgePointList, {
        params: {id: row.id}
      }).then(() => {
        this.$message.success('删除成功')
        this.onGetDetail()
      }, err => {
        this.$message.error(err.message)
      })
    },
    onGetDetail() {
      if (this.isCreate) return
      const params = {
        dataId: this.currentTagId,
        dataType: this.dataType
      }
      this.$axios.get(this.$API.getProductKnowledgePointList, {params}).then(res => {
        const {tagId, tagName, knowledgePointList} = res.data
        this.currentTagId = tagId
        this.createTagNameText = tagName
        this.cacheText = this.createTagNameText
        this.dataList = knowledgePointList
      }, err => {
        this.$message.error(err.message)
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.tag-box {
  display: flex;
  align-items: center;
}
.none-input ::v-deep {
  width: 240px;
  margin-right: 10px;
}
.table-box {
  padding: 15px 0;
}
</style>
